@font-face {
  font-family: HeartBreakingBad;
  src: url(/fonts/HeartBreakingBad.otf);
}

@font-face {
  font-family: CooperMedium;
  src: url(/fonts/CooperMedium.ttf);
}

@font-face {
  font-family: ArialNarrow;
  src: url(/fonts/ArialNarrow.ttf);
}


@font-face {
  font-family: BreakingCooper;
  src: url(/fonts/BreakingCooper-Medium.ttf);
}

@font-face {
  font-family: MontserratAlternates;
  src: url(/fonts/MontserratAlternates-SemiBold.ttf);
}

html, body {
  margin: 0;
  background: rgb(8,22,2);
  background: linear-gradient(90deg, #062206 0%, #0a310d 50%, #062206 100%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /* hack to prevent backgorund color change */
  transition: background-color 50000s ease-in-out 0s;
}